import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import theme from './assets/theme'
import '@fontsource/roboto'
import './index.css'

import './i18n'

import 'fingerprint/build/main'


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Provider store={store}>
                    <Suspense>
                        <App />
                    </Suspense>
                </Provider>
            </BrowserRouter>
        </ThemeProvider>
    </StyledEngineProvider>
)

reportWebVitals()
