import { createTheme, adaptV4Theme } from '@mui/material/styles'

const theme = createTheme(adaptV4Theme({
    breakpoints: {
        values: {
            mobile: 540,
            tablet: 768,
            laptop: 1025,
            desktop: 1280
        }
    }
}))

export default theme
