export const CARD_HOLDER_MOCK = 'Card Holder'
export const CVC_MOCK = '123'

interface IObjectData {
    [key: string]: string | undefined
}

export function removeEmptyRecordsFromObject(data: IObjectData) {
    for (const key in data) {
        if (!data[key]) {
            delete data[key]
        } else {
            data[key] = data[key]?.trim()

            if (!data[key]) {
                delete data[key]
            }
        }
    }

    return data
}

export function getPreferredLanguage() {
    const language = window.navigator.language

    return language.split('-')[0]
}

export const isEmptyObject = (object?: Record<string, string> | null | unknown) => {
    if (!object) {
        return true
    }

    return !Object.keys(object).length
}

export function createRequestId() {
    return window.location.origin + '_ge_pay_' + Date.now()
}

export function addCommas(n: string){
    const rx=  /(\d+)(\d{3})/

    return String(n).replace(/^\d+/, function(w){
        while(rx.test(w)){
            w= w.replace(rx, '$1,$2')
        }

        return w
    })
}

export const useThrottle = (func: (...args: any[]) => void, delay: number) => {
    let timeout: NodeJS.Timeout | null = null

    return (...args: any[]) => {
        if (timeout) {
            return
        }
        func(...args)
        timeout = setTimeout(() => {
            timeout = null
        }, delay)
    }
}

export const validateCardExpirationDate = (value: string, isAllowedExp: boolean, minYear: number, minMonth: number): boolean => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear() % 100
    const currentMonth = currentDate.getMonth() + 1

    const inputMonth = Number(value.substring(0, 2))
    const inputYear = Number(value.substring(2, 4))

    if (inputMonth < 1 || inputMonth > 12) {
        return false
    }

    if (isAllowedExp) {
        return inputYear > minYear || (inputYear === minYear && inputMonth >= minMonth)
    } else {
        return inputYear > currentYear || (inputYear === currentYear && inputMonth >= currentMonth)
    }
}
