import axios from 'axios'

import createSignature, { ISignatureMessage } from '../common/createSignature'
import { ModeEnum } from '../enums/mode.enum'

export const API_URL = process.env.REACT_APP_MODE === ModeEnum.Development
    ? process.env.REACT_APP_API_URL
    : window.location.origin

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(async function (config) {
    const sessionId = `f${(~~(Math.random() * 1e8)).toString(16)}`
    const message: ISignatureMessage = {
        method: config.method?.toUpperCase(),
        url: config?.url,
        sessionId,
        body: JSON.stringify(config?.data) || ''
    }

    const signature = config.headers['X-REQUEST-SIGNATURE']||(await createSignature({ key: 'key', message }))
    config.headers['X-CUSTOMER-SESSION-ID'] = sessionId
    config.headers['X-REQUEST-SIGNATURE'] = signature

    return config
})

export default api
