import { getPreferredLanguage } from './utils'

const currentDate = new Date

export interface IExtraData {
    ScreenHeight: number
    ScreenWidth: number
    JavaEnabled: boolean
    TimeZoneOffset: number
    Region: string
    UserLang: string
    ColorDepth: number
    UserAgent: string
    PersonalId?: string
    RFC?: string
    State?: string
    AddressStreet?: string
    AddressNumber?: string
    acceptHeader: string
    javaScriptEnabled: boolean
    code3ds?: string
    kushkiJwt?: string
    deviceSessionId?: string
    canvasHash?: string
}

function getExtraData(): IExtraData {
    return {
        ScreenHeight: window.innerHeight || document.body.clientHeight,
        ScreenWidth: window.innerWidth || document.body.clientWidth,
        JavaEnabled: window.navigator.javaEnabled(),
        TimeZoneOffset: currentDate.getTimezoneOffset(),
        Region: window.navigator.language,
        UserLang: getPreferredLanguage(),
        ColorDepth: screen.colorDepth,
        UserAgent: window.navigator.userAgent,
        acceptHeader: 'text/html',
        javaScriptEnabled: true,
        ...window.fingerprint
    }
}

export default getExtraData
